/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

/*.mat-mdc-form-field:hover,  .mat-mdc-form-field-type-mat-input:hover, .mat-mdc-form-field-has-icon-prefix:hover, .mat-form-field-appearance-fill:hover,  .mat-mdc-floating-label:hover{
    background-color: white !important;
}*/

.mat-slide-toggle {
    background-color: #033f78 !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle {
    background-color: #033f78 !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
    background-color: #033f78 !important;
}

.mat-mdc-tab-list {
    border-bottom: solid 1px lightgrey !important;
}

hr {width: 99%;}

/*.mat-mdc-table {
    border: 1px solid black !important;
}*/

.mat-mdc-table .mdc-data-table__header-row {
    background-color: #d3d3d3 !important;
}

.mat-mdc-tab.mdc-tab{
    flex-grow: 0 !important;
}

.mat-mdc-form-field-infix {
    padding: 0 !important;
    min-height: unset !important;
    display: flex !important;
    align-items: end !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    background: rgb(230, 230, 230) !important;
    border: 1px solid black !important;
    border-radius: 10px !important;
    color: black !important;
    fill: black !important;
    max-height: 90% !important;
    padding: 24px !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
    text-align: center;
}

.mat-icon {
    padding: unset !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: unset !important;
}

.mat-mdc-text-field-wrapper .mdc-text-field  .mdc-text-field--filled .mdc-text-field--no-label {
    background-color: unset !important;
}

.owl-dt-container-buttons {
    height: unset !important;
}

.mat-mdc-input-element {
    margin-top: 10px !important;
    font-size: 14px !important;
}

.mat-mdc-select-value {
    margin-top: 10px !important;
    font-size: 14px !important;
}

.mdc-floating-label {
    top: 18px !important;
}

.mat-mdc-tab-body-wrapper {
    flex-grow: 2;
}

.mdc-text-field {
    padding: 0 4px !important;
}


.mat-mdc-tab-body-content {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}


a.mat-list-item.active {
    color: #ccc;
}

html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.tableheader {
    border-bottom: 1px solid currentColor;
    text-decoration: none;

}

.wrapForScroll {

    display: flex;
    flex-direction: column;
    align-items: stretch;
    color: white;
    border: 2px solid black;
    border-radius: 5px;
    box-shadow: 5px 5px #888888;
}

.wrapForHeader {
    background: linear-gradient(0deg, rgba(165, 165, 165, 0) 0%, rgba(145, 145, 146, 0.9864974710982659) 100%);
}

.viewport {
    min-width: 270px;
    max-width: 370px;
    padding-right: 5px;
    padding-bottom: 15px;
}


.viewport2 {
    height: 250px;
    min-width: 170px;
    max-width: 270px;

    padding-right: 5px;
    padding-bottom: 15px;
    border: 1px solid black;
}

.hover-class:hover {
    background-color: black;
}

@media only screen and (max-width: 850px) {
    .viewport {

        min-width: 70px;

    }
}

.dialogStyles .mat-dialog-container {
    background: rgb(230, 230, 230);
    border: 1px solid black;
    border-radius: 10px;
    color: black;
    fill: black;
    max-height: 90%;
}

::ng-deep mat-sidenav-content {
    height: 98% !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}


